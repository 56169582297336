<template>
    <grid-view 
        title="Подсказки к работе"
        create
        create-title="Создать подсказку"
        url-create="/hint/create"
        url-update="/hint/update/:id"
        store-module="hint"
        :headers="[
            { text: 'ID', value: 'id', width: 1 },
            { text: 'Название', value: 'name', width: 1, sortable: false },
            { text: '', value: 'edit', width: 1, sortable: false },
            { text: '', value: 'remove', width: 1, sortable: false }
        ]"
        :format="formatItems"
    />
</template>
<script>
import GridView from '@/components/crud/GridView.vue'
export default {
    name: 'HintIndex',
    components: { GridView }, 
    data () {
        return {}
    },
    async created () {
        await this.$store.dispatch(`hint/filter`, { type: 'job-hint' });
    },
    methods: {
        formatItems (items) { return items } 
    }
}
</script>